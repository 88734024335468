import { HTTP, baseURL } from "../utils/http-common";

export const c2g = {

    //apiLink: process.env.NODE_ENV !== 'development' ? 'https://covered2go.co.uk/api' : 'https://covered2go.api.rushcloud.site',
    //apiLink: 'https://covered2go.api.rushcloud.site',
    //contentLink: 'https://covered2go.co.uk/content-block',

    //apiLink: 'http://bbqe.co.uk/covered2go',
    //contentLink: 'https://bbqe.co.uk/content-block',

    // List of policy types
    policyType: {'AT': 'Annual Multi-Trip', 'ST': 'Single Trip', 'LS': 'Longstay', 'CR-AT': 'Cruise - Annual Multi-Trip', 'CR-ST': 'Cruise - Single Trip'},

    // List of area types
    area: {'uk': 'United Kingdom', 'euro': 'Europe',  'ausnz': 'Australia and New Zealand', 'wwexc': 'Worldwide exc. USA, Canada and Caribbean', 'wwinc': 'Worldwide inc. USA, Canada and Caribbean' },

    relationship: { 'individual': 'Individual', 'couple': 'Couple', 'oneparent': 'Single Parent Family', 'family': 'Family', 'group': 'Group of Adults' },

    /*passengerAmount: { 1:}*/

    // Pass in a policy number and get policies offered
    getProductsOffered(id) {
        return HTTP.get(`scheme/products_offered/${id}`)
        .catch((error) => { console.log(error); });
    },

    // Get endorsements from API
    getEndorsements(policyType) {
        return HTTP.post('scheme/endorsements', {
            'policy_type': policyType
        })
        .catch((error) => { console.log(error); });
    },

    // Get cover limits
    getCoverLimits(where = '') {
        return HTTP.post('scheme/cover_limits', {'where': where})
        .catch((error) => { console.log(error); });
    },

    // Get cover limits
    getTravelAreas() {
        return HTTP.post('scheme/travel_areas')
        .catch((error) => { console.log(error); });
    },

    // Get activities
    getActivities(activitySearch = '') {
        return HTTP.post('scheme/activities', {
            'activitySearch': activitySearch
        }).catch((error) => { console.log(error); });
    },

    // Get activity pack based on activities
    assessActivityPack(activities = '', winter_sports = 0) {
        return HTTP.post('scheme/assess_activity_pack', {
            'activities': activities,
            'winter_sports': winter_sports
        }).catch((error) => { console.log(error); });
    },

    // Policy Insurance Product Information Documents
    getIPID(product, ws = false, cr = false, pt = '') {
        let file = '';

        if (cr) {
            file = 'ipids/2021/mul/C2G-IPID-Cruise';
        } else if (pt === 'ST') {
            file = 'ipids/2021/mul/C2G-IPID-ST';
        } else if (pt === 'AT') {
            file = 'ipids/2021/mul/C2G-IPID-AT';
        } else if (pt === 'LS') {
            file = 'ipids/2021/mul/C2G-IPID-LS';
        } else if (ws) {
            file = ''
        }

        return `${baseURL}/_assets/documents/${file}.pdf`;
    },

    // Policy Wordings
    getPolicyWordings(product, ws = false, cr = false, year = '', pt = '') {

        let file = '';

        if (year >= 8) {
            if (cr) {
                file = `policy-wordings/${year}/C2G-Cruise`;
            } else if (pt === 'ST') {
                file = `policy-wordings/${year}/C2G-ST`;
            } else if (pt === 'AT') {
                file = `policy-wordings/${year}/C2G-AT`;
            } else if (pt === 'LS') {
                file = `policy-wordings/${year}/C2G-LS`;
            }
        } else {
            if(pt === 'LS') {
                file = `policy-wordings/${year}/C2G-LS`;
            } else if(!ws && cr) {
                file = `policy-wordings/${year}/C2G-Cruise`;
            } else if(ws && cr) {
                file = `policy-wordings/${year}/C2G-Cruise`;
            } else if(product === 'PLATINUM' && !ws) {
                file = `policy-wordings/${year}/C2G-Platinum`;
            } else if(product === 'SILVER' && !ws) {
                file = `policy-wordings/${year}/C2G-Silver`;
            } else if(product === 'GOLD' && !ws) {
                file = `policy-wordings/${year}/C2G-Gold`;
            } else if(product === 'PLATINUM' && ws) {
                file = `policy-wordings/${year}/C2G-Platinum-WS`;
            } else if(product === 'GOLD' && ws) {
                file = `policy-wordings/${year}/C2G-Gold-WS`;
            } else if(product === 'SILVER' && ws) {
                file = `policy-wordings/${year}/C2G-Silver-WS`;
            } else {
                file = `policy-wordings/${year}/C2G-Longstay`;
            }
        }

        return `${baseURL}/_assets/documents/${file}.pdf`;
    },
};