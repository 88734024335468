import axios from 'axios';

// C2G
export const baseURL = process.env.VUE_APP_BASE_URL;

// CNT
export const contentBaseURL = process.env.VUE_APP_CONTENT_BLOCK_URL;


export const HTTP = axios.create({
    auth: {
        username: 'c2g',
        password: '=D/f/neK(dX!5f2K7buN'
    },
    // auth: {
    //     username: 'rush',
    //     password: 'beckwith'
    // },
    baseURL: baseURL,
    contentBaseURL: contentBaseURL
});

export const HTTPContent = axios.create({
    baseURL: contentBaseURL
});