<template>
    <div>
        <!-- Covered2Go top logo panel -->
        <nav id="top-panel">

            <!-- Social media banner -->
            <div class="container-fluid social">
                <div class="container level">
                    <div class="level-left">
                        <div class="level-item">
                            <a href="https://www.facebook.com/Covered2go" target="_blank"><i class="fab fa-facebook-square"/></a> |
                            <a href="https://twitter.com/covered2go" target="_blank"><i class="fab fa-twitter"/></a> |
                            <a href="https://www.instagram.com/covered2go" target="_blank"><i class="fab fa-instagram"/></a>
                        </div>
                    </div>
                    <!-- Customer hub -->
                    <div class="level-right">
                        <div v-if="!customer">
                            <button class="button is-info is-small customer-hub-btn" @click="siteData.customerLoginModalActive = true">
                                <i class="fas fa-user"></i>
                                Customer Hub
                            </button>
                        </div>
                        <div v-else>
                            <router-link :to="{name:'customerHome'}" tag="button" class="button is-info is-small customer-hub-btn" @click="siteData.customerLoginModalActive = true">
                                <i class="fas fa-user"></i>
                                {{`${customer.title} ${customer.surname}`}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Top banner containing Logo -->
            <div class="container header header-container">
                <div class="level">
                    <div style="display: flex;" v-if="affiliateHeader">
                        <div class="level-item affiliate">
                            <div>
                                <router-link :to="{name:'home'}" oncontextmenu="return false;">
                                    <picture>
                                        <source srcset="../assets/logo-HD.webp" type="image/webp">
                                        <source srcset="../assets/logo-HD.png" type="image/png">
                                        <img id="c2g_logo" src="../assets/logo-HD.webp" loading="lazy" alt="covered2go">
                                    </picture>
                                </router-link>
                            </div>
                            <div>
                                <p>in partnership with</p>
                            </div>
                            <!-- // TODO Refactor this as more affliates are being added -->
                            <div class="affiliate-logo" v-if="affiliateHeader == 'broadwaytravel'">
                                <img src="../assets/images/affiliates/broadway-travel-logo-web.png" />
                            </div>
                            <div class="affiliate-logo" v-if="affiliateHeader == 'inspiredluxuryescapes'">
                                <img style="padding: 10px;" src="../assets/images/affiliates/inspired-logo-web.png" />
                            </div>
                            <div class="affiliate-logo" v-if="affiliateHeader == 'premierni'">
                                <img style="padding: 10px;" src="../assets/images/affiliates/premier-travel-logo.png" />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="level-item">
                            <router-link :to="{name:'home'}" oncontextmenu="return false;">
                                <picture>
                                    <source srcset="../assets/logo-HD.webp" type="image/webp">
                                    <source srcset="../assets/logo-HD.png" type="image/png">
                                    <img id="c2g_logo" src="../assets/logo-HD.webp" loading="lazy" alt="covered2go">
                                </picture>
                            </router-link>
                        </div>
                    </div>
                    <!-- TrustPilot -->
                    <div class="level-item tp-item">
                        <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="50b7a9eb00006400051f090e" data-style-height="40px" data-style-width="100%" data-theme="light">
                            <a href="https://uk.trustpilot.com/review/covered2go.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                        </div>
                    </div>
                    <!-- Defaqto logo -->
                    <div class="level-item defaqto-logo">
                        <picture>
                            <source srcset="/img/defaqto.png" type="image/png">
                            <img id="logo-image" src="/img/defaqto.png" loading="lazy" alt="Defaqto expert rated logo">
                        </picture>
                    </div>
                    <div>
                        <!-- Get a quote -->
                        <div class="level-item quote-item">
                            <router-link tag="button" class="button get-a-quote is-info hvr-glow" :to="{name:'getQuote'}">
                                <i class="far fa-check-circle"/>
                                <strong>GET A QUOTE</strong>
                            </router-link>
                        </div>
                        <!-- Retrieve a quote -->
                        <div class="level-item retrieve-item">
                            <button class="button retrieve-a-quote is-primary is-info hvr-glow" @click="showPopup()">
                                <i class="far fa-check-circle"/>
                                <strong>RETRIEVE A QUOTE / RENEWAL</strong>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="level trust">
                    <div class="level-item">
                        <picture>
                            <img src="/img/excellent-trustpilot-rating.png" loading="lazy" height="44" alt="Person with 5 stars over their head">
                        </picture>
                        "Excellent" Trustpilot Rating
                    </div>
                    <div class="level-item">
                        <picture>
                            <img src="/img/award-winning.png" loading="lazy" height="44" alt="Rosette with a star">
                        </picture>
                        Award-Winning Travel Insurance
                    </div>
                    <div class="level-item">
                        <picture>
                            <img src="/img/24hr-emergency-assistance.png" loading="lazy" height="44" alt="24 Hour Emergency Medical Assistance">
                        </picture>
                        24 Hour Emergency Medical Assistance
                    </div>
                    <div class="level-item">
                        <picture>
                            <img src="/img/all-medical-conditions-considered.png" loading="lazy" height="44" alt="A heart with pulse inscription">
                        </picture>
                        All Medical Conditions Considered
                    </div>
                </div>
            </div>
        </nav>
        <base-navigation-bar :siteData="siteData"/>
        <retrieve-quote-modal :siteData="siteData"/>
        <customer-hub-login-modal :siteData="siteData"/>
    </div>
</template>

<script>
    import BaseNavigationBar from "./common/BaseNavigationBar";
    import RetrieveQuoteModal from "./common/RetrieveQuoteModal";
    import CustomerHubLoginModal from "./customer-hub/CustomerHubLoginModal";
    import { mapActions, mapGetters } from "vuex";
    import { maintenance } from "../mixins/maintenance";

    export default {
        name: "Header",
        props: ['siteData'],
        computed: {
            ...mapGetters({
                customer: 'customer',
                affiliation: 'affiliation'
            }),
            affiliateHeader() {
                // Dirty hack to get url as the header is loaded before the routes
                // So we can add the broadway logo in when the affiliate is used.
                let urlParams = new URLSearchParams(window.location.search);
                let profile = urlParams.get('profile')
                const affiliationCookie = this.getAffiliation();
                if (affiliationCookie != null) {
                  profile = affiliationCookie;
                }
                // let pathname = window.location.pathname.replace(/\//,'');
                let affiliate = null;
                let policy_affiliation = null;

                // For retrieving quotes
                // Need this check as when the page first loads there is no policy object in the store
                if (this.$store.getters.policy) {
                    // Use the store getter directly as the affiliation link is on the policy object
                    // Loaded from the database
                    policy_affiliation = this.$store.getters.policy.policy.affilliation.toLowerCase();
                }

                if (profile === "broadwaytravel" || policy_affiliation == "broadwaytravel" || this.affiliation == "broadwaytravel") {
                    affiliate = "broadwaytravel";
                    this.setAffiliation("broadwaytravel");
                }

                if (profile === "inspiredluxuryescapes" || policy_affiliation == "inspiredluxuryescapes" || this.affiliation == "inspiredluxuryescapes") {
                    affiliate = "inspiredluxuryescapes";
                    this.setAffiliation("inspiredluxuryescapes");
                }

                if (profile === "premierni" || policy_affiliation == "premierni" || this.affiliation == "premierni") {
                    affiliate = "premierni";
                    this.setAffiliation("premierni");
                }
                return affiliate;
            }
        },
        methods: {
            ...mapActions({
                setAffiliation: 'setAffiliation'
            }),
            async showPopup() {

                await this.maintenanceCheck();

                if (this.check === 0) {
                    this.$router.push({name: 'maintenance'});
                } else {
                    this.siteData.retrieveModalActive = true;
                }
            },
          getAffiliation ()  {
            const name = 'profile' + "=";
            const cDecoded = decodeURIComponent(document.cookie); //to be careful
            const cArr = cDecoded .split('; ');
            let res;
            cArr.forEach(val => {
              if (val.trim().indexOf(name) === 0) res = val.substring(name.length);
            })
            return res;
          }
        },
        mixins: [maintenance],
        components: {
            CustomerHubLoginModal,
            BaseNavigationBar,
            RetrieveQuoteModal
        }
    }
</script>

<style lang="scss" scoped>
    #logo-image {
        height: 113px;
        cursor: pointer;
        transition: 0.3s;
    }
    #logo-image:hover {
        opacity: 0.85;
    }
    #c2g_logo {
        height:80px;
        cursor: pointer;
        transition: 0.3s;
    }
    #top-panel {
        height: auto;
        margin: 0 !important;
    }
    .trust {
        color: $c2g_blue;
    }
    .trustpilot-widget {
        padding: 20px 0 0 0;
        right: 10px;
        margin-bottom: 20px;
    }
    .social {
        background-color: #eeeeee;
        padding-left: 10px;
        font-size: 22px;
        a {
            color: $c2g_blue;
            transition: 0.4s;
            margin: 2px 5px;
        }
        a:hover { color: $c2g_orange; }
    }

    .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .affiliate {
        flex-direction: column;
    }

    .affiliate-logo {
        width: 200px;
    }

    .level {
        margin-bottom: 5px !important;
    }
    .header {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    // Buttons
    .get-a-quote {
        background: linear-gradient(170deg, #eb892e, $c2g_orange);
        border-color: $c2g_orange;
        width: 200px;
        height: 40px;
        strong {
            margin-left: 5px;
        }
    }
    .retrieve-a-quote {
        background: $c2g_blue;
        border-color: $c2g_blue;
        width: 300px;
        height: 40px;
        strong {
            margin-left: 5px;
        }
    }
    .customer-hub-btn {
        margin: 5px 0;
        transition: 0.3s;
        .fas {
            margin-right: 5px;
        }
    }
    .customer-hub-btn:hover {
        background-color: $c2g_orange !important;
        border-color: $c2g_orange !important;
    }

    .quote-item {
        margin-bottom: 5px;
    }

    .defaqto-logo {
        padding-right: 50px;
    }

    // Hide top buttons on mobile
    @media screen and (max-width: $small_tablet_width) {
        .tp-item { display: none; }
        .trust { display: none; }
        .defaqto-logo {display: none;}
    }

    // Hide top buttons on mobile
    @media screen and (max-width: $mobile_width) {
        // .retrieve-item { display: none; }
        .tp-item { display: none; }
        .trust { display: none; }
        // .quote-item { display: none; }
        .level-right { margin-top: 5px !important; text-align: center; }
        .defaqto-logo {display: none;}
    }

    @media screen and (max-width: '768px') {
        #top-panel {
            .header {
                padding: 0 5px;
            }
        }
    }

</style>