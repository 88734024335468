<template>
    <b-navbar type="is-info" class="c2g-nav container" spaced>
        <template slot="start">
            <b-navbar-dropdown label="Travel Insurance" id="ti-dropdown">
                <div class="columns">
                    <div class="column">
                        <b-navbar-item tag="router-link" :to="{name:'singleTrip'}" :active="$route.name === 'singleTrip'">Single Trip Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'annualTrip'}" :active="$route.name === 'annualTrip'">Annual Multi Trip Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'covid19Cover'}" :active="$route.name === 'covid19Cover'">Covid-19 Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'longstayTrip'}" :active="$route.name === 'longstayTrip'">Longstay Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'medicalConditions'}" :active="$route.name === 'medicalConditions'">Pre-Existing Medical Conditions Cover</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'winterSportsTravelInsurance'}" :active="$route.name === 'winterSportsTravelInsurance'">Winter Sports Cover</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'cruiseTravelInsurance'}" :active="$route.name === 'cruiseTravelInsurance'">Cruise Cover</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'sportsActivitiesCover'}" :active="$route.name === 'sportsActivitiesCover'">Sports & Activities Cover</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'businessTravelInsurance'}" :active="$route.name === 'businessTravelInsurance'">Business Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'groupTravelInsurance'}" :active="$route.name === 'groupTravelInsurance'">Group Travel Insurance</b-navbar-item>
                    </div>
                    <div class="column">
                        <b-navbar-item tag="router-link" :to="{name:'ukCover'}" :active="$route.name === 'ukCover'">UK Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'europeCover'}" :active="$route.name === 'europeCover'">Europe Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'worldwideTravelInsurance'}" :active="$route.name === 'worldwideTravelInsurance'">Worldwide Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'travelByDestination'}" :active="$route.name === 'travelByDestination'">Travel by Destination</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'nonUKResidents'}" :active="$route.name === 'nonUKResidents'">Non UK Residents Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'over65s'}" :active="$route.name === 'over65s'">Over 65s Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" to="/blog/ghic-information-everything-you-need-to-know" >EHIC / GHIC Information</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'familyTravelInsurance'}" :active="$route.name === 'familyTravelInsurance'">Family Travel Cover</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'travelInsuranceHelp'}" :active="$route.name === 'travelInsuranceHelp'">Travel Insurance Help</b-navbar-item>
                        <!--
                        <b-navbar-item tag="router-link" :to="https://www.covered2go.co.uk/blog/ghic-information-everything-you-need-to-know">EHIC / GHIC Information</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'worldwideTravelInsurance'}" :active="$route.name === 'worldwideTravelInsurance'">Worldwide Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'worldwideTravelInsurance'}" :active="$route.name === 'worldwideTravelInsurance'">Worldwide Travel Insurance</b-navbar-item>
                        <b-navbar-item tag="router-link" :to="{name:'worldwideTravelInsurance'}" :active="$route.name === 'worldwideTravelInsurance'">Worldwide Travel Insurance</b-navbar-item>
                        -->
                    </div>
                </div>
            </b-navbar-dropdown>
            <b-navbar-dropdown label="Help">
                <b-navbar-item tag="router-link" :to="{name:'aboutUs'}">About us</b-navbar-item>
                <b-navbar-item tag="router-link" :to="{name:'contactUs'}">Contact us</b-navbar-item>
                <b-navbar-item tag="router-link" :to="{name:'faqs'}">Travel Insurance FAQs</b-navbar-item>
                <b-navbar-item tag="router-link" :to="{name:'policyWordingsMUL'}">Policy Wordings</b-navbar-item>
                <b-navbar-item tag="router-link" :to="{name:'claim'}">Making a claim</b-navbar-item>
                <b-navbar-item tag="router-link" :to="{name:'mapsDirectory'}">MaPS Directory</b-navbar-item>
                <!-- <b-navbar-item tag="router-link" :to="{name:'whichArea'}">What countries are covered by our policies?</b-navbar-item>-->
                <!--<b-navbar-item tag="router-link" :to="{name:'activityPacks'}">What activities are covered under our policies?</b-navbar-item>-->
            </b-navbar-dropdown>
            <b-navbar-item tag="router-link" :to="{name:'reviews'}" :active="$route.name === 'reviews'">Reviews</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{name:'blog'}" :active="$route.name && $route.name.includes('blog') && !$route.path.includes('covid')">Blog</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{name:'covid19Cover'}" :active="$route.path.includes('covid')">Covid-19 Travel Insurance</b-navbar-item>
            <b-navbar-item tag="router-link" :to="{name:'defaqto'}" :active="$route.name === 'defaqto'">Our Defaqto Star Ratings</b-navbar-item>
        </template>
        <template slot="end">
            <global-search/>
        </template>
    </b-navbar>
</template>

<script>
    import GlobalSearch from "./GlobalSearch";

    export default {
        name: "NavigationBar",
        props: ['siteData'],
        components: {
            GlobalSearch
        },
    }
</script>

<style lang="scss" scoped>
    .c2g-nav {
        border-radius: 4px;
        border-bottom: 3px solid $c2g-orange;
    }
    /deep/ {
        .navbar-item {
            font-weight: bold;
        }
        .navbar-dropdown {
            .navbar-item {
                background-color: white;
                font-weight: normal;
                margin: 1px 10px;
            }
            .navbar-item:hover {
                background-color: $c2g_blue;
                color: white;
                font-weight: bold;
            }
        }

    }
</style>