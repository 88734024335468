export const policyAPI = {
    methods: {
        // Query API
        getQuote() {
            return this.$http.post('policy/create', {
                'start_date': this.$moment(this.quoteData.dates[0]).format('YYYY-MM-DD'),
                'end_date': this.$moment(this.quoteData.dates[1]).format('YYYY-MM-DD'),
                'policy_type': this.quoteData.policyType,
                'travel_zone': this.quoteData.travelZone,
                'relationship': this.quoteData.relationship,
                'ages': this.quoteData.ages,
                'cruise': this.quoteData.endorsements['cruise'] === 'Yes',
                'trip_cost': this.quoteData.tripCost,
                'save': true,
                //RR-181 send marketing flag
                'marketing': this.quoteData.marketing,
                'affiliation': this.quoteData.affilliation
            })
            .catch((error) => { console.log(error); });
        },
        // Select Product
        selectProduct(id, product) {
            return this.$http.post('policy/select_product', {
                'id': id,
                'product': product
            })
            .catch((error) => { console.log(error); });
        },
        // Passengers
        setPassengers(id, passengers) {
            return this.$http.post('policy/set_passengers', {
                'id': id,
                'passengers': passengers
            })
            .catch((error) => { console.log(error); });
        },
        // Endorsements
        setEndorsements(id, endorsements) {
            return this.$http.post('policy/set_endorsements', {
                'id': id,
                'endorsements': endorsements
            })
            .catch((error) => { console.log(error); });
        },
        // Save Policy
        savePolicy(policy) {
            return this.$http.post('policy/save', {
                'id': policy.quote.id,
                'policy': JSON.stringify(policy.policy),
                'details': JSON.stringify(policy.details),
                'endorsements': JSON.stringify(policy.endorsements),
                'quote': JSON.stringify(policy.quote),
                'passengers': JSON.stringify(policy.passengers),
            })
            .catch((error) => { console.log(error); });
        },
        // Create up-sell policy
        createUpsellPolicy(id) {
            return this.$http.get('policy/create_upsell/' + id)
            .catch((error) => { console.log(error); });
        },
        // Retrieve a quote / policy
        retrievePolicy(id) {
            return this.$http.get('policy/get/' + id);
        },
        // Make a policy live
        makePolicyLive(id) {
            return this.$http.post('policy/make_live', {
                'id': id
            }).catch((error) => { console.log(error); });
        },
        // Apply a promo code to policy
        applyPromoCode(id, code) {
            return this.$http.post('policy/apply_promocode', {
                'id': id,
                'code': code
            }).catch((error) => { console.log(error); });
        },
        // Send a confirmation email
        sendConfirmationEmail(id) {
            return this.$http.post('confirmation_email', {
                'id': id
            }).catch((error) => { console.log(error); });
        },
        // Generate policy certificate
        generateCertificate(id) {
            return this.$http.get('certificate/generate/' + id)
            .catch((error) => { console.log(error); });
        },
        // Get certificate link
        certificateLink(id) {
            return this.$http.get('certificate/link/' + id);
        },
        // Get policy wording
        policyWordingLink(id) {
            return this.$http.get('policy_wording/' + id);
        },
        // Get status disclosure
        statusDisclosureLink() {
            return this.$http.get('status_disclosure');
        },
        // Get status disclosure
        termsOfBusinessLink() {
            return this.$http.get('terms_of_business');
        },
        // Get the pay request
        payRequest(id) {
            return this.$http.get('pay/request/' + id)
            .catch((error) => { console.log(error); });
        },
        // Add a marketing entry
        addMarketing(id) {
            return this.$http.get('scheme/add_marketing/' + id);
        },
        // RR-181 Call new add marketing endpoint
        updateMarketing(id, email, optIn) {
            return this.$http.post('scheme/update_marketing', {
                'id': id,
                'email': email,
                'optIn': optIn
            })
            .catch((error) => { console.log(error); });
        }
    }
};