// Poll the mutations
export const actions = {
    setPolicy: ({ commit }, payload) => {
        commit('setPolicy', payload);
    },
    setReqPolicy: ({ commit }, payload) => {
        commit('setReqPolicy', payload);
    },
    setUpsellPolicy: ({ commit }, payload) => {
        commit('setUpsellPolicy', payload);
    },
    setAffiliation: ({ commit }, payload) => {
        if (payload !== null) {
            const twoWeeks = new Date(Date.now() + 12096e5);
            document.cookie = "profile=" + payload + ";expires=" + twoWeeks + ";path=/";
        }
        commit('setAffiliation', payload);
    },
    setCallCentre: ({ commit }, payload) => {
        commit('setCallCentre', payload);
    },
    setCustomer: ({ commit }, payload) => {
        commit('setCustomer', payload);
    }
};